/** @jsx jsx */
import { Box, Flex, Heading, Text, jsx } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import StyledLink from "./Link"
import { Link } from "gatsby"

const TopicCard = ({ details, number }) => {
  const { title, excerpt, slug, topicCustomFields } = details
  const { author } = topicCustomFields

  const featuredImage = {
    data: details.featuredImage?.node?.localFile?.childImageSharp
      ?.gatsbyImageData,
    alt: details.featuredImage?.node?.altText || details.title || ``,
  }

  const authorData = {
    image: {
      data: author?.featuredImage?.node?.localFile?.childImageSharp
        ?.gatsbyImageData,
      alt:
        author?.featuredImage?.node?.altText ||
        author?.authorFields?.name ||
        ``,
    },
    name: author?.authorFields?.name,
    title: author?.authorFields?.shortbio,
  }

  return (
    <Flex
      sx={{
        backgroundColor: "hsl(180deg, 13%, 98%)",
        p: "24px 30px",
        flexDirection: "column",
        borderRadius: 5,
        position: "relative",
        // border: "1px solid",
        borderColor: "border.base",
        "&:hover": {
          // boxShadow: `0 0 0 1px ${theme.colors.secondary.base}`,
          backgroundColor: "hsl(180deg, 13%, 97%)",
        },
        mx: "auto",
        width: "100%",
      }}
    >
      {/* Image */}
      <Flex sx={{ mb: 3, alignItems: "center", justifyContent: "center" }}>
        {featuredImage?.data && (
          <GatsbyImage image={featuredImage.data} alt={featuredImage.alt} />
        )}
      </Flex>

      {/* Content */}
      <Box sx={{ flex: 1 }}>
        <Heading
          as="h3"
          variant="md"
          sx={{ fontSize: ["24px", null, null, null, "27px"] }}
        >
          {title}
        </Heading>

        <Text
          as="div"
          variant="uppercase"
          mt={4}
          sx={{ fontSize: "11px", color: "text.light2x" }}
        >
          Introduction by
        </Text>

        {/* Author */}
        <Flex
          mt={3}
          sx={{
            alignItems: "flex-start",
          }}
        >
          {/* Author image */}
          {authorData.image.data && (
            <Box
              sx={{
                backgroundColor: "background.dark2x",
                borderRadius: "100%",
                width: "100%",
                maxWidth: "60px",
                height: "60px",
                mr: 3,
                overflow: "hidden",
                mt: 1,
              }}
            >
              <GatsbyImage
                image={authorData.image.data}
                alt={authorData.image.alt}
                imgStyle={{
                  objectFit: "cover",
                  borderRadius: "100%",
                }}
                style={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                }}
              />
            </Box>
          )}

          {/* Author content */}
          <Box sx={{ width: "100%" }}>
            <Text as="div" sx={{ fontSize: 1, fontWeight: "medium" }}>
              {authorData?.name}
            </Text>
            <Text
              as="div"
              sx={{
                fontSize: 1,
                color: "text.light2x",
                lineHeight: "1.4",
                mt: 1,
              }}
            >
              {authorData?.title}
            </Text>
          </Box>
        </Flex>
      </Box>

      {/* Read more */}
      <Box mt={4}>
        {/* {excerpt && (
          <Text
            variant="sm"
            as="div"
            dangerouslySetInnerHTML={{ __html: excerpt }}
            sx={{
              fontSize: 2,
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              color: "text.light",
              "& p": {
                margin: "0 0 24px",
              },
              "& p:last-child": {
                margin: 0,
              },
            }}
          />
        )} */}
        <Box sx={{ zIndex: 2, position: "relative" }}>
          <StyledLink url={`/${slug}/`} urlTarget="internal" arrow>
            Learn more
          </StyledLink>
        </Box>
      </Box>
      {/* <Flex
        sx={{
          position: "absolute",
          top: "-18px",
          left: "-16px",
          width: "36px",
          height: "36px",
          lineHeight: "1",
          backgroundColor: "secondary.base",
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "19px",
          borderRadius: "100%",
          fontWeight: "heading",
        }}
      >
        {number}
      </Flex> */}
      <Link
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        to={`/${slug}/`}
      />
    </Flex>
  )
}

export default TopicCard
