/** @jsx jsx */
import { Link } from "gatsby"
import { Box } from "theme-ui"
import { css, jsx } from "@emotion/react"
import theme from "../gatsby-plugin-theme-ui"
import { BsArrowRight } from "react-icons/bs"

type StyledLinkProps = {
  url: string
  urlTarget: "internal" | "external"
  arrow?: boolean
}

const Arrow = () => (
  <Box
    as="span"
    sx={{
      width: "18px",
      height: "18px",
      ml: 2,
      transition: "all 0.12s ease-in-out",
      "& svg": {
        width: "100%",
        height: "100%",
      },
    }}
  >
    <BsArrowRight strokeWidth="1" />
  </Box>
)

const baseStyles = css`
  color: ${theme.colors.primary.base};
  font-size: 17px;
  font-weight: ${theme.fontWeights.medium};
  display: inline-flex;
  align-items: center;
  line-height: 1;
  letter-spacing: ${theme.letterSpacings.links};

  &:hover span {
    margin-left: 12px;
  }
`

const StyledLink: React.FC<StyledLinkProps> = ({
  url,
  urlTarget,
  arrow,
  children,
}) => {
  if (urlTarget === "internal") {
    return (
      <Link css={baseStyles} to={url}>
        {children}
        {arrow && <Arrow />}
      </Link>
    )
  } else {
    return (
      <a css={baseStyles} href={url}>
        {children}
        {arrow && <Arrow />}
      </a>
    )
  }
}

export default StyledLink
