/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx, Heading, Text, Box, Container, Grid, Flex } from "theme-ui"
import { useLocation } from "@reach/router"
import Layout from "../components/Layout"
import HeroVideo from "../components/hero/HeroVideo"
import Button from "../components/Button"
import TopicCard from "../components/TopicCard"
import PostCard from "../components/PostCard"
import PostCardLarge from "../components/PostCardLarge"
import ContributeCta from "../components/ContributeCta"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { blogPosts, latestPost, topics, homePage, config } = data
  const { pathname } = useLocation()

  return (
    <Layout
      announcementBanner={
        <a href="/neurips21/" target="_blank">
          Visit the NeurIPS Data-Centric AI 2021 Workshop page &rarr;
        </a>
      }
    >
      <SEO yoastSeo={homePage?.seo} />
      <HeroVideo>
        <Heading variant="xxl" as="h1">
          Data-centric AI Resource Hub
        </Heading>
        <Text mt={4} as="p" sx={{ fontSize: "20px", color: "text.light" }}>
          Find the latest developments and best practices compiled here, so you
          can begin your Data-centric AI journey!
        </Text>
      </HeroVideo>

      <Box as="section">
        <Container variant="narrow2x" pt={["140px", null, null, null, "173px"]}>
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Heading as="h2" variant="lg">
              What is Data-centric AI?
            </Heading>
            <Text
              as="p"
              sx={{
                fontWeight: "body",
                color: "text.light2x",
                mt: 5,
                mb: 3,
                fontSize: ["18px", null, null, null, "22px"],
              }}
            >
              Data-centric AI is the discipline of systematically engineering
              the data used to build an AI system.
            </Text>
            {/* <Button as="internal_link" variant="primary" url="/about/">
              Learn more
            </Button> */}
          </Flex>
        </Container>
        <Container variant="container" pt={[10]} pb={10}>
          <Heading
            as="p"
            variant="uppercase"
            sx={{ textAlign: "center", mb: 7 }}
          >
            Explore Topics
          </Heading>
          <Grid
            sx={{
              gridTemplateColumns: ["1fr", null, "1fr 1fr", "repeat(3, 1fr)"],
              gridGap: 7,
            }}
          >
            {topics.edges.map(({ node }, index) => (
              <TopicCard details={node} number={index + 1} key={node.id} />
            ))}
          </Grid>
        </Container>
      </Box>

      <Box as="section">
        <Container pb={10}>
          <Heading as="h2" variant="lg" mb={7}>
            Latest Posts
          </Heading>

          {latestPost.edges[0] && (
            <PostCardLarge post={latestPost.edges[0].node} />
          )}

          <Grid
            sx={{
              gridTemplateColumns: ["1fr", "1fr 1fr", null, "repeat(3, 1fr)"],
              gridGap: "64px 40px",
            }}
          >
            {blogPosts.edges.map(({ node }, index) => {
              if (index === 0) return null
              return <PostCard key={node.id} post={node} />
            })}
          </Grid>

          <Box sx={{ textAlign: "center" }} mt={8}>
            <Button url="/blog/" variant="primary" as="internal_link" arrow>
              View all posts
            </Button>
          </Box>
        </Container>
      </Box>

      <ContributeCta
        socialShare={{
          title: homePage?.seo?.title || config.siteMetadata.title,
          url: `${config.siteMetadata.siteUrl}${pathname}`,
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    config: site {
      siteMetadata {
        siteUrl
        title
      }
    }

    homePage: wpPage(slug: { eq: "home-page" }) {
      ...WpPageFields
    }

    blogPosts: allWpPost(
      limit: 7
      sort: { fields: [date], order: DESC }
      filter: { status: { eq: "publish" } }
    ) {
      edges {
        node {
          ...WpPostCardFields
        }
      }
    }

    latestPost: allWpPost(
      limit: 1
      sort: { fields: [date], order: DESC }
      filter: { status: { eq: "publish" } }
    ) {
      edges {
        node {
          ...WpPostCardFields
          featuredImageLarge: featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 900, placeholder: BLURRED)
                }
              }
              altText
            }
          }
        }
      }
    }

    topics: allWpTopic(sort: { fields: menuOrder, order: ASC }) {
      edges {
        node {
          ...WpTopicFields
        }
      }
    }
  }
`

export default IndexPage
