/** @jsx jsx */
import { Box, Grid, Container, jsx } from "theme-ui"
import VideoPopup from "../VideoPopup"
import { StaticImage } from "gatsby-plugin-image"

const HeroVideo = ({ children }) => (
  <Box
    bg="background.dark"
    sx={{
      paddingTop: ["40px", null, null, "30px"],
      // paddingBottom: [0, null, null, "140px"],
      paddingBottom: [0, null, null, "10px"],
    }}
    as="section"
  >
    <Container sx={{ height: "100%" }}>
      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, null, "410px auto", "515px auto"],
          height: "100%",
          gridGap: [9, null, null, 9, 11],
          alignItems: ["flex-start", null, null, "center"],
        }}
      >
        <Box
          sx={{
            textAlign: ["center", null, null, "left"],
            mt: [0, null, null, "-83px"],
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            position: "relative",
            mb: ["-40px", null, null, "-48px"],
            mt: [null, null, null, 0],
          }}
        >
          <VideoPopup
            image={
              <StaticImage
                src="../../images/home/hero/video-poster.png"
                alt=""
                placeholder="blurred"
                loading="eager"
                width={800}
                objectFit="cover"
                objectPosition="center"
                style={{
                  height: "100%",
                }}
                imgStyle={{
                  borderRadius: "4px",
                  height: "100%",
                }}
              />
            }
            sx={{
              position: ["relative", null, null, "relative"],
              height: ["270px", "420px", null, "375px"],
            }}
          />
        </Box>
      </Grid>
    </Container>
  </Box>
)

export default HeroVideo
