/** @jsx jsx */
import React, { useState } from "react"
import styled from "@emotion/styled"
import { Box, Flex, jsx } from "theme-ui"
import { BsFillPlayFill, BsX } from "react-icons/bs"

const VideoPopup = ({ image, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box {...props} sx={{ maxWidth: "800px", mx: "auto" }}>
      <Box
        sx={{
          height: "100%",
          position: "relative",
          border: "12px solid",
          borderRadius: 10,
          borderColor: "background.base",
          overflow: "hidden",
          boxShadow: "3px 1px 18px 3px rgb(212, 221, 221)",
        }}
      >
        {isOpen ? (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/SjzruCZudJw?autoplay=1&rel=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              borderRadius: 3,
            }}
          ></iframe>
        ) : (
          <>
            {image}
            <PlayButton onClick={() => setIsOpen(true)} />
          </>
        )}
      </Box>
      {/* {isOpen && (
        <Flex
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            zIndex: 100,
            alignItems: "center",
            justifyContent: "center",
            p: 4,
          }}
          onClick={(e: React.MouseEvent): void => {
            if (e.target === e.currentTarget) {
              setIsOpen(false)
            }
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
              position: "relative",
              width: "100%",
              maxWidth: "800px",

              "&:after": {
                paddingTop: "56.25%",
                display: "block",
                content: '""',
              },
            }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/SjzruCZudJw?autoplay=1&rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                borderRadius: 3,
              }}
            ></iframe>
          </Box>
          <CloseButton onClick={() => setIsOpen(false)} />
        </Flex>
      )} */}
    </Box>
  )
}

const CloseButton = props => (
  <button
    {...props}
    sx={{
      position: "absolute",
      top: "24px",
      right: "24px",
      border: "none",
      borderRadius: "100%",
      width: "50px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      p: "12px",
      cursor: "pointer",
    }}
  >
    <BsX color="black" sx={{ width: "100%", height: "100%" }} />
  </button>
)

const PlayButton = props => (
  <Button {...props}>
    <Circle
      sx={{
        size: ["50px", null, null, "85px"],
        boxShadow: "0 0 0 11px rgba(255, 255, 255, 0.50)",
      }}
    >
      <BsFillPlayFill
        sx={{
          color: "tertiary.base",
        }}
      />
    </Circle>
  </Button>
)

const Button = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  cursor: pointer;
  background: rgba(34, 48, 59, 0.34);
  border-radius: 4px;
`

const Circle = styled.div`
  background: ${props => props.theme.colors.background.base};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 36px;

  ${Button}:hover & {
    background: ${props => props.theme.colors.background.dark};
  }
`

export default VideoPopup
